import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// import { post } from '../slices/api';
import { AxiosError } from 'axios';
import { post } from './api';
import { toBase64 } from 'src/utils/utils';

type BonusState = {
  isLoading: boolean;
  error: any;
  data: any;
  name: any;
  bonusValue: any;
  isError: boolean;
  description: string;
  title: string;
};

const initialState: BonusState = {
  isLoading: false,
  error: null,
  data: null,
  name: null,
  bonusValue: null,
  isError: false,
  description: '',
  title: '',
};

const slice = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setModalInfos(state, action) {
      state.description = action.payload.description;
      state.title = action.payload.title;
      state.isError = action.payload.isError;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setPhoneFormData(state, action) {
      action.payload.check = ['acceptedTerms', 'acceptedEmail', 'acceptedSMS', 'acceptedWhatsapp'];
      console.log('setPhoneFormData: ', action.payload);
      // state.codeFormData = action.payload
    },
  },
});

export default slice.reducer;

export const { setPhoneFormData } = slice.actions;

export function createBonus(membercard: string, bonusValue: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if(Number(bonusValue) < 10) {
        throw new Error("minimumValue")
      } else if(Number(bonusValue) > 20000) {
        throw new Error("maximumValue")
      }
      await post('/benefits/promoter-credit-bonus-to-user', { memberCard: membercard, value: Number(bonusValue) });
      dispatch(slice.actions.stopLoading());
      dispatch(
        slice.actions.setModalInfos({
          title: 'Bônus gerado com sucesso!',
          description:
            'Informe para o cliente confirmar o recebimento do bônus em sua carteira dentro do VIVA Center Norte.',
          isError: false,
        })
      );
      return true;
    } catch (error) {
      dispatch(slice.actions.stopLoading());

      if(error?.message === "minimumValue") {
        dispatch(slice.actions.setModalInfos(
          {
            title: "Valor inserido abaixo do limite permitido.",
            description: "Você pode inserir bônus a partir de 10. Tente novamente seguindo as regras da campanha.",
            isError: true
          }
        ));
      } else if(error?.message === "maximumValue") {
        dispatch(slice.actions.setModalInfos(
          {
            title: "Valor inserido acima do limite permitido.",
            description: "Você pode inserir bônus de até 20000. Tente novamente seguindo as regras da campanha.",
            isError: true
          }
        ));
      } else {
        if (error instanceof AxiosError) {
          if (error.response?.status === 400) {
            dispatch(
              slice.actions.setModalInfos({
                title: error.response?.data?.title,
                description: error.response?.data?.description,
                isError: true,
              })
            );
          } else {
            dispatch(
              slice.actions.setModalInfos({
                title: 'Error interno no servidor!',
                description: 'Tente novamente mais tarde.',
                isError: true,
              })
            );
          }
        } else {
          dispatch(
            slice.actions.setModalInfos({
              title: 'Error interno no servidor!',
              description: 'Tente novamente mais tarde.',
              isError: true,
            })
          );
        }
      }
      return error?.message ?? 'Erro interno!';
    }
  };
}

export function createBonusLarCenter(memberCard: string, value: string, cnpj: string, issueDate: Date, photos?: any){
  return async () => {
    dispatch(slice.actions.startLoading());
    try{
      let photosAux: any = []
      if(photos.length > 0){
        const map = await photos.map(async (photo: any) => {
          const photoBase64 = await toBase64(photo);
          const res = await post('/auth/files/upload-single', { name: photo.name, base64: photoBase64 });
          photosAux.push(res.data.data.url)
        })
        await Promise.all(map)
      }
      await post('/invoices/promoter-credit-lar-center', { memberCard, value: parseFloat(value.replace(/\./g, '').replace(/,/g, '.')), cnpj, issueDate, photos: photosAux });
      dispatch(slice.actions.stopLoading());
    }catch(e) {
      dispatch(slice.actions.stopLoading());
      if(e.response.data.message){
        throw e.response.data.message || 'Erro interno!';
      }
    }
  }
}