import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axiosStore from 'src/utils/axiosStore';

type PromoterState = {
  isLoading: boolean,
  error: any,
  list: any
}

const initialState: PromoterState = {
  isLoading: false,
  error: null,
  list: []
};

const slice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

export function verifyStore(code: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      var response = await axiosStore.post('/auth/login-management', { code: code, userType: "shopkeeper" });
      dispatch(slice.actions.stopLoading());
      return response.data
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      throw error
    }
  };
}