import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// import axios from '../../utils/axios';
import { get, post, put } from './api';
import { get as storeGet, put as storePut } from './apiStore';

type BenefitState = {
  isLoading: boolean;
  isLoadingNotViva: boolean;
  error: any;
  benefitsList: { id: string; name: string; image: string; benefitType: string, tiers: string[] }[];
  reservation: any;
  benefit: any;
};

const initialState: BenefitState = {
  isLoading: false,
  isLoadingNotViva: false,
  error: null,
  benefitsList: [],
  benefit: null,
  reservation: null,
};

const slice = createSlice({
  name: 'benefits',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingNotViva(state) {
      state.isLoadingNotViva = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    stopLoadingNotViva(state) {
      state.isLoadingNotViva = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getBenefitsSuccess(state, action) {
      state.isLoading = false;
      state.benefitsList = action.payload;
    },

    getBenefitSuccess(state, action) {
      state.benefit = action.payload;
      state.isLoading = false;
    },

    getReservationSuccess(state, action) {
      state.reservation = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export function getBenefits() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let benefits;
      if (window.location.pathname.includes('/promoter')) {
        benefits = await get('/benefits/all-benefits');
      } else {
        benefits = await storeGet('/benefits/all-benefits');
      }
      dispatch(slice.actions.getBenefitsSuccess(benefits.data));
    } catch (e) {
      console.log(e);
      dispatch(slice.actions.getBenefitSuccess([]));
      dispatch(slice.actions.hasError(e));
    }
  };
}

export function getReservation(membercard: string, benefitId: string, benefitType: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let res;
      if (window.location.pathname.includes('/promoter')) {
        res = await get(`/benefits/get-user-redemption?memberCard=${membercard}&benefitId=${benefitId}&benefitType=${benefitType}`);
      } else {
        res = await storeGet(`/benefits/get-user-redemption?memberCard=${membercard}&benefitId=${benefitId}&benefitType=${benefitType}`);
      }

      const reservation = res.data;

      if (reservation?.user?.phone) {
        if (reservation.user.phone.length === 11) {
          reservation.user.phone = reservation.user.phone.replace(
            /(\d{2})(\d{5})(\d{4})/,
            '($1) $2-$3'
          );
        } else {
          reservation.user.phone = reservation.user.phone.replace(
            /(\d{2})(\d{4})(\d{4})/,
            '($1) $2-$3'
          );
        }
      }
      dispatch(slice.actions.getReservationSuccess(reservation));
      return reservation;
    } catch (e) {
      console.log(e);
      dispatch(slice.actions.hasError(e));
      if (Array.isArray(e?.response?.data?.message)) {
        return { error: e?.response?.data?.message[0]?.msg };
      } else {
        return { error: e?.response?.data?.message || e?.message };
      }
    }
  };
}

export function confirmReservation(memberCard: string, benefitId: string, benefitType: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (window.location.pathname.includes('/promoter')) {
        await put(`/benefits/confirm-use`, {
          memberCard,
          benefitId,
          benefitType
        });
      } else {
        await storePut(`/benefits/confirm-use`, {
          memberCard,
          benefitId,
          benefitType
        });
      }
      dispatch(slice.actions.stopLoading());
      return true;
    } catch (e) {
      console.log(e);
      dispatch(slice.actions.hasError(e));
      return e?.response?.data;
    }
  };
}

export function createReservation(memberCard: string, benefitId: string, benefitType: string, bypassPersonLimit: boolean = false, bypassRedeemLimit: boolean = false, description: string = '', children: number = 0, adults: number = 0) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (window.location.pathname.includes('/promoter')) {
        await put(`/benefits/redeem-and-confirm-use`, {
          memberCard,
          benefitId,
          benefitType,
          bypassPersonLimit,
          bypassRedeemLimit,
          children,
          adults,
          description
        });
      } else {
        await storePut(`/benefits/redeem-and-confirm-use`, {
          memberCard,
          benefitId,
          benefitType,
          bypassPersonLimit,
          bypassRedeemLimit,
          children,
          adults,
          description
        });
      }
      dispatch(slice.actions.stopLoading());
      return true;
    } catch (e) {
      dispatch(slice.actions.hasError(e));
      return e?.response?.data;
    }
  };
}

export function getBenefitDetails(benefitId: string, benefitType: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let benefit;
      if (window.location.pathname.includes('/promoter')) {
        benefit = await get(`/benefits/promoter-details?benefitId=${benefitId}&benefitType=${benefitType}`);
      } else {
        benefit = await storeGet(`/benefits/promoter-details?benefitId=${benefitId}&benefitType=${benefitType}`);
      }
      dispatch(slice.actions.getBenefitSuccess(benefit.data[0]));
    } catch (e) {
      console.log(e);
      dispatch(slice.actions.hasError(e));
    }
  };
}

export function getEventDetails(benefitId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const benefit = await get(`/benefits/events/available-times?eventId=${benefitId}`);
      dispatch(slice.actions.getBenefitSuccess(benefit.data));
    } catch (e) {
      console.log(e);
      dispatch(slice.actions.hasError(e));
    }
  };
}

export function createBenefitNotViva(user: any, benefit: any, bypassPersonLimit: boolean = false, description: string = '') {
  return async () => {
    dispatch(slice.actions.startLoadingNotViva());
    try {
      await post(`/benefits/redeem-and-confirm-use-not-viva`, {
        user,
        benefit,
        bypassPersonLimit,
        description
      });
      dispatch(slice.actions.stopLoadingNotViva());
      return true;
    } catch (e) {
      dispatch(slice.actions.stopLoadingNotViva());
      console.log(e);
      dispatch(slice.actions.hasError(e));
      throw e?.response?.data;
    }
  };
}