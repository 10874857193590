import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { setSession, setSessionStore } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      if (window.location.pathname.includes('/promoter')) {
        try {
          const accessToken = localStorage.getItem('@cnPromoter-accessToken');
          const refreshToken = localStorage.getItem('@cnPromoter-refreshToken');
  
          if (accessToken && refreshToken) {
            setSession(accessToken, refreshToken);
            
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user: {
                  accessToken,
                  refreshToken,
                },
              },
            });
          } else {
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } catch (err) {
          console.error(err);
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } else {
        try {
          const accessToken = localStorage.getItem('@cnStore-accessToken');
          const refreshToken = localStorage.getItem('@cnStore-refreshToken');
  
          if (accessToken && refreshToken) {
            setSessionStore(accessToken, refreshToken);
            
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user: {
                  accessToken,
                  refreshToken,
                },
              },
            });
          } else {
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } catch (err) {
          console.error(err);
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }
    };

    initialize();
  }, []);

  const login = async (token: string) => {
    try{
      const response = await axios.get('/auth/login?token='+token);
      const { accessToken, refreshToken } = response.data;
  
      setSession(accessToken, refreshToken);
  
      await getData();
      return 'Logado'
    }catch(e){
      return 'Não possui cadastro'
    }
  };

  const getGuest = async (token?: string | null) => {
    try{
      var response
      if(token){
        response = await axios.get('/auth/guest-token/'+token);
      }else{
        response = await axios.get('/auth/guest-token');
      }
      const { accessToken } = response.data;
      setSession(accessToken, 'guest');
    }catch(e){
      console.log(e)
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    // setSession(null, null);
    dispatch({
      type: Types.Initial,
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
  };

  const getData = async () =>{
    try{
      var response = await axios.get('/user/getData');
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: true,
          user: response.data,
        },
      });
      return true
    }catch(e){
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      return false
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        getData,
        getGuest
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
